$(document).on('turbolinks:load', function () {
    if (document.querySelector('.review-status-select')) {
        document.querySelectorAll(".review-status-select .form-check input").forEach(item => {
            item.addEventListener('click', event => {
                let submitButtonText = (event.target.value === 'published' ? "Publish event" : "Request change");
                document.querySelector("#new_review > input[type=submit]").value = submitButtonText;
            })
        });
    }
});
