import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["imageInput", "filename", "imagePreview", "imagePreviewContainer"]

  connect() {
    this.imageInputTarget.addEventListener('change', this.previewImage.bind(this))
  }

  previewImage(e) {
    if (this.imageInputTarget.files && this.imageInputTarget.files[0]) {
      let filename = this.imageInputTarget.files[0].name
      let reader = new FileReader();

      reader.onload = (event) => {
        this.imagePreviewTarget.setAttribute('src', event.target.result);
        this.imagePreviewTarget.style.width = '300px';
      };

      reader.readAsDataURL(this.imageInputTarget.files[0]);
      this.imagePreviewContainerTarget.setAttribute('class', 'pb3');
      this.filenameTarget.innerHTML = filename;
    }
  }

  deletePreview() {
    this.imagePreviewTarget.setAttribute('src', '');
    this.imagePreviewContainerTarget.setAttribute('class', 'd-none');
    this.imageInputTarget.value = '';
    this.filenameTarget.innerHTML = this.filenameTarget.dataset.placeholder;
  }
}
