let toast_editor;
import { Loader } from '@googlemaps/js-api-loader';
import Tagify from '@yaireo/tagify'

$(document).on('turbolinks:load', function () {
    const Editor = require("@toast-ui/editor");
    const Viewer = require("@toast-ui/editor/dist/toastui-editor-viewer");

    if (document.getElementById("map")) {

        const locationInput = document.getElementById("event_location");

        // API KEY managed by info@ehlert-it.de Google account
        const mapLoader = new Loader({
            apiKey: 'AIzaSyDi0gqY1Zkb8YY9dV5vWlK7EV1DEtgK558',
            version: "weekly",
            libraries: ['places']
        });

        const arnsbergCoordinates = { lat: 51.407313179493045, lng: 8.053946919154765 };

        const placesAutocompleteOptions = {
            componentRestrictions: { country: "deu" },
            fields: [
                "formatted_address",
                "geometry",
                "name",
                "place_id",
                "url"
            ],
            origin: arnsbergCoordinates,
            strictBounds: false,
        };

        const mapOptions = {
            center: {
                lat: arnsbergCoordinates.lat,
                lng: arnsbergCoordinates.lng
            },
            zoom: 11
        };


        mapLoader.load()
            .then((google) => {

                let currentMarker;
                const map = new google.maps.Map(document.getElementById("map"), mapOptions);

                if (document.getElementById('event-form').classList.contains('edit_event')) {
                    let place_data = JSON.parse(document.getElementById('event_place_data').value)
                    if (place_data?.geometry?.location) {
                        map.setCenter(place_data.geometry.location);
                        currentMarker = new google.maps.Marker({
                            map: map,
                            position: place_data.geometry.location,
                            animation: google.maps.Animation.DROP,
                        });
                        map.set('zoom', 16);
                    }
                }

                const autocompleteService = new google.maps.places.Autocomplete(locationInput, placesAutocompleteOptions);

                autocompleteService.addListener('place_changed', placeChanged)

                function placeChanged() {
                    let place = autocompleteService.getPlace();

                    if (!place.geometry) {
                        locationInput.placeholder = "Start typing location";
                    } else {
                        if (currentMarker) {
                            currentMarker.setMap(null);
                        }
                        currentMarker = new google.maps.Marker({
                            map: map,
                            position: place.geometry.location,
                            animation: google.maps.Animation.DROP,
                        });
                        map.setCenter(place.geometry.location);
                        map.set('zoom', 16);

                        document.getElementById("event_place_data").value = JSON.stringify(place);
                    }
                }
            })
            .catch(e => {
                console.error("Error loading gmaps");
            });
    }

    function tagTemplate(tagData) {
        return `
        <tag title="${tagData.name}"
                contenteditable='false'
                spellcheck='false'
                tabIndex="-1"
                class="tagify__tag ${tagData.class ? tagData.class : ""}"
                ${this.getAttributes(tagData)}>
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div>
                <span class='tagify__tag-text'>
                    <strong>${tagData.name}</strong>
                </span>
            </div>
        </tag>
    `
    }

    function suggestionItemTemplate(tagData) {
        return `
        <div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item ${tagData.class ? tagData.class : ""}'
            tabindex="0"
            role="option">

            <strong>${tagData.name}</strong>
        </div>
    `
    }

    if (document.querySelector('input[name=tags]')) {
        let categoriesData = Array.from(document.querySelectorAll("#categories-data .category-data-item"))
            .map(element => {
                return { ...element.dataset }
            })
            .map(item => {
                return { value: item.id, name: item.name, id: item.id }
            });


        let categoriesInput = document.querySelector('input[name=tags]');

        // init Tagify script on the above inputs
        let tagify = new Tagify(categoriesInput,
            {
                searchKeys: ['name'],
                whitelist: categoriesData,
                templates: {
                    tag: tagTemplate,
                    dropdownItem: suggestionItemTemplate
                },
                userInput: false,
                dropdown: {
                    tagTextProp: 'name',
                    maxItems: 100,
                    enabled: 0,             // <- show suggestions on focus
                    closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
                }
            });

        function updateCategoriesIds(e) {
            const categoryIdsField = document.querySelector('#event_category_ids');

            let ids = tagify.value.map(tag => {
                return tag.id
            });
            categoryIdsField.value = ids.join(' ');
        }

        tagify.on('add', updateCategoriesIds).on('remove', updateCategoriesIds);
    }

    if (document.querySelector("#event-viewer")) {
        let toast_viewer = new Viewer({
            el: document.getElementById('event-viewer'),
            height: 'auto',
            initialValue: document.getElementById('event-content-md').innerText,
            usageStatistics: false
        });
    }

    if (document.querySelector("#editor")) {
        toast_editor = new Editor({
            el: document.getElementById('editor'),
            initialValue: document.getElementById('event-content-md').innerText,
            height: 'auto',
            initialEditType: 'wysiwyg',
            usageStatistics: false
        });
    }

    document.addEventListener('click', function (e) {
        if (e.target) {
            if (e.target.id === 'submit-event-button' && e.target.closest('#event-form')) {
                e.preventDefault();
                document.getElementById('event_content').value = toast_editor.getMarkdown();
                e.target.closest('form').submit();
            }
        }
    });
})
