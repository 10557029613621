// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import NestedForm from 'stimulus-rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

require("jquery");
require("../src/event.js")
require("../src/event_review.js")

$(document).on('turbolinks:load', function () {
    require("@coreui/coreui/dist/js/coreui.min")
});

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Rails = Rails

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
application.register('nested-form', NestedForm);

const images = require.context('../images', true)

$(document).on('turbolinks:load', function () {
    $("[data-link]").click(function (e) {
        if (e.target.tagName.toLowerCase() === 'input' && e.target.type === 'checkbox') {
            console.log(e.target)
            if (e.target.checked) {
                Turbolinks.visit(e.target.dataset.linkFeature);
            } else {
                Turbolinks.visit(e.target.dataset.linkUnfeature);
            }
        }
        if (e.target.tagName.toLowerCase() !== 'a' && e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'span') {
            Turbolinks.visit($(this).data("link"));
        }
    });
});


